import consts from "consts/consts";
import { isConnectedFromLocalISP } from "helpers/localIsp";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";

import squareLogo from "../../../public/assets/logo_login.png";
import rectangleLogo from "../../../public/assets/logo_login.png";
import popupLogo from "../../../public/assets/logo_login_modal.png";

export const LogoTypes = {
  SQUARE: "SQUARE",
  RECTANGLE: "RECTANGLE",
};

export default class LogoLogin extends Component {
  render() {
    const isLocalIsp = isConnectedFromLocalISP();

    let logoMapping;

    if (this.props.type === LogoTypes.SQUARE) {
      logoMapping = {
        main: squareLogo,
        local: "/assets/local_logo.png",
      };
    }

    if (this.props.type === LogoTypes.RECTANGLE) {
      logoMapping = {
        main: rectangleLogo,
        local: "/assets/local_logo_horizontal.png",
      };
    }

    if (this.props.isPopup) {
      logoMapping = {
        main: popupLogo,
        local: "/assets/logo_login_modal.png",
      };
    }

    return (
      <Fragment>
        <img
          id="logo-app"
          src={isLocalIsp ? logoMapping.local : logoMapping.main}
          className={this.props.className}
          alt={`${consts.name}'s logo`}
        />
      </Fragment>
    );
  }
}

LogoLogin.defaultProps = {
  type: LogoTypes.SQUARE,
};

LogoLogin.propTypes = {
  type: PropTypes.oneOf(Object.values(LogoTypes)),
};
